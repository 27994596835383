@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.organism__splash-screen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-image: url('/images/ui/splash/bg.webp');
  // background-size: cover;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#286a96+0,4a83b5+16,004677+88 */
  background: linear-gradient(45deg,  rgba(40,106,150,1) 0%,rgba(74,131,181,1) 16%,#004676 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  display: grid;
  place-items: flex-end;
  color: #fff;

  .glass-effects-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 1100px;
    max-height: 1000px;
    // background-image: url('/images/ui/splash/bg2.webp');
    background-image: url('/images/ui/splash/bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    background-position: top right;
  }

  .content-container {
    height: 60vh;
    margin-top: auto;
    margin-bottom: 0;
    padding: 30px;

    .header {
      display: flex;
      align-items: flex-end;
      padding: 0;
      margin: 0;
      font-size: 35px;
      font-weight: bold;
      opacity: 0;
      animation: fade-in-up linear .3s 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      img {
        width: 75px;
        margin-right: 10px;
      }

      h3 {
        // padding-left: 85px;
        margin: 0;
        margin-top: 5px;
        font-weight: normal;
      }
    }

    p {
      max-width: 480px;
      line-height: 20px;
      margin-top: 60px;
      margin-bottom: 30px;
      font-weight: 200;
      opacity: 0;
      animation: fade-in-up linear .5s 1.3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .loading-container {
      opacity: 0;
      animation: fade-in-up linear .2s 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    // .enter-button-container {
    //   max-width: 48rem;
    //   display: flex;
    //   justify-content: flex-end;
    // }

    // .form-login {
    //   display: block;
    //   max-width: 380px;

    //   .inputs-container {
    //     display: flex;
    //     justify-content: space-between;
    //   }

    //   .actions-container {
    //     display: flex;
    //     justify-content: flex-end;
    //     margin-top: 10px;

    //     a {
    //       color: #fff;
    //       font-size: 12px;
    //       margin-right: 10px;
    //     }
    //   }
    // }
  }

  .version-container {
    text-align: right;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;

    a {
      color: #fff;
      font-weight: bold;
    }
  }

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    // TODO: add specifications for devices that are greater than phones
    // example: .content { ... }
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
