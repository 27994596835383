@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.page__homeContainer {
  .splash-screen-container {
    position: relative;
    z-index: 9;
    transition: opacity .5s;
    opacity: 1;

    &[data-fading] {
      opacity: 0;
    }
  }
}
